<template>
  <div>
    <div class="d-flex ">
        <v-autocomplete
                :label="`Created by`"
                :items="workers"
                v-model="createdByUuid"
                item-text="fullname"
                item-value="uuid"
                style="max-width: 500px"
                prepend-inner-icon="mdi-account-search"
                clearable               
                hide-details
                outlined
                @change="getLeadLogs"
                dense
              ></v-autocomplete>
        <v-spacer></v-spacer>
      <v-btn @click="getLeadLogs" text color="primary"
        ><v-icon>mdi-refresh</v-icon> Refresh</v-btn
      >
    </div>
    <template>
      <v-data-table
        :headers="headers"
        :items="logs"
        :loading="loading"
        single-expand
        :expanded.sync="expanded"
        show-expand
        :footer-props="footerProps"
        :server-items-length="totallogs"
        item-key="uuid"
        :options.sync="options"
        class="elevation-1"
      >
        <template v-slot:[`item.createdAt`]="{ item }">
          {{ formatDate(item.createdAt) }}
        </template>
        <template v-slot:[`item.createdBy`]="{ item }">
          {{ item.createdBy ? item.createdBy.fullname : "System" }}
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <div class="pa-2 wrapper-expanded">
              <v-stepper min-width="1400" min-height="400" non-linear v-model="stp1">
                <v-stepper-header>
                  <v-stepper-step editable step="1"> Before </v-stepper-step>
                  <v-divider></v-divider>
                  <v-stepper-step editable step="2"> Changes </v-stepper-step>
                  <v-divider></v-divider>
                  <v-stepper-step :editable="fieldchanges.length!=0"  step="3"> Fields Changed {{ `(${fieldchanges.length})` }} </v-stepper-step>
                  <v-divider></v-divider>
                  <v-stepper-step step="4" editable> After </v-stepper-step>
                </v-stepper-header>
                <v-stepper-items>
                  <v-stepper-content step="1">
                    <v-card min-height="250" elevation="0">
                      <v-card-text>
                        <template
                          v-if="item && item.metadata && item.metadata.before"
                        >
                          <v-row no-gutters>
                            <v-col
                              cols="12"
                              class="mt-2"
                              sm="3"
                              v-for="(value, name) in item.metadata.before"
                              :key="name"
                            >
                              <div
                                class="
                                  text-caption
                                  font-weight-bold
                                  text--secondary 
                                "
                              >
                                {{ name }}
                              </div>
                              <div class="text-body-1 text-uppercase">
                                {{ value ? value:'-' }}
                              </div>
                            </v-col>
                          </v-row>
                        </template>
                      </v-card-text>
                    </v-card>
                    <v-btn color="primary" @click="stp1 = 2"> Next </v-btn>
                  </v-stepper-content>

                  <v-stepper-content step="2">
                    <v-card min-height="250" elevation="0">
                      <v-card-text>
                        <template
                          v-if="item && item.metadata && item.metadata.changes"
                        >
                          <v-row no-gutters>
                            <v-col
                              cols="12"
                              class="mt-2"
                              sm="3"
                              v-for="(value, name) in item.metadata.changes"
                              :key="name"
                            >
                              <div
                                class="
                                  text-caption
                                  font-weight-bold
                                  text--secondary 
                                "
                              >
                                {{ name }}
                              </div>
                              <div class="text-body-1 text-uppercase">
                                {{ value ? value:'-' }}
                              </div>
                            </v-col>
                          </v-row>
                        </template>
                      </v-card-text>
                    </v-card>
                    <v-btn @click="stp1 = 1"  class="mr-2"> Back </v-btn>
                    <v-btn color="primary" @click="stp1 = 3"> Next </v-btn>
                  </v-stepper-content>
                  <v-stepper-content step="3">
                    <v-card min-height="250"  class="fill-width fill-height test" elevation="0">
                      <v-card-text >                       
                          <v-row no-gutters>
                            <v-col
                              cols="12"
                              class="mt-2 d-flex"
                              sm="3"
                              v-for="(value,index) in fieldchanges"
                              :key="index"
                            >

                            <div>
                                <div
                                class="
                                  text-caption
                                  font-weight-bold
                                  text--secondary 
                                "
                              >
                                {{ value.label }}
                              </div>
                              <div class="text-body-1 ">
                                {{value.valueb ? value.valueb:'-' }}
                              </div>
                            </div>
                            
                              <div class="mx-4 font-weight-bold">to</div>
                              <div>
                                <div
                                class="
                                  text-caption
                                  font-weight-bold
                                  text--secondary 
                                "
                              >
                                {{ value.label }}
                              </div>
                              <div class="text-body-1">
                                {{value.valuea?value.valuea:'-'}}
                              </div>
                              </div>
                            
                            </v-col>
                          </v-row>
                       
                      </v-card-text>                     
                    </v-card>
                    <v-btn @click="stp1 = 2" class="mr-2"> Back </v-btn>
                    <v-btn color="primary" @click="stp1 = 4"> Next </v-btn>
                  </v-stepper-content>

                  <v-stepper-content step="4">
                    <v-card min-height="250" elevation="0">
                      <v-card-text>
                        <template
                          v-if="item && item.metadata && item.metadata.after"
                        >
                          <v-row no-gutters>
                            <v-col
                              cols="12"
                              class="mt-2"
                              sm="3"
                              v-for="(value, name) in item.metadata.after"
                              :key="name"
                            >
                              <div
                                class="
                                  text-caption
                                  font-weight-bold
                                  text--secondary 
                                "
                              >
                                {{ name }}
                              </div>
                              <div class="text-body-1 text-uppercase">
                                {{ value ? value:'-' }}
                              </div>
                            </v-col>
                          </v-row>
                        </template>
                      </v-card-text>
                    </v-card>
                    <v-btn @click="stp1 =3"> Back </v-btn>
                  </v-stepper-content>
                </v-stepper-items>
              </v-stepper>
            </div>
          </td>
        </template>
      </v-data-table>
    </template>
  </div>
</template>
<script>
import { getAPI } from "@/api/axios-base";
import moment from "moment";
import { mapState } from "vuex";
export default {
  name: "lead-logs",
  props:{
    workers:Array
  },
  data() {
    return {
      logs: [],
      totallogs: 0,
      stp1: "1",
      loading: false,
      createdByUuid:null,      
      options: {},
      expanded: [],
      fieldchanges:[],
      footerProps: { "items-per-page-options": [25, 50, 100, 250, 500, 1000] },
      headers: [
        {
          text: "Created At",
          align: "start",
          sortable: false,
          value: "createdAt",
        },
        {
          text: "Created By",
          align: "start",
          sortable: false,
          value: "createdBy",
        },
        {
          text: "Note",
          align: "start",
          sortable: false,
          value: "note",
        },
        { text: "", value: "data-table-expand" },
      ],
    };
  },
 
  watch: {
    options(val) {
      if (val != {}) {
        this.getLeadLogs();
      }
    },
    expanded:{
        handler(){
            this.stp1= "1";
            this.fieldchanges=[]
            this.getFieldsChanges()
        }
    }
  },
  methods: {
    getLeadLogs() {
      const { page, itemsPerPage } = this.options;
      const itmper = itemsPerPage ? itemsPerPage : 15;
      const p = page ? page : 1;
      this.loading = true;
      let body  = {
        limit: itmper,
          offset: (p - 1) * itmper,
          leadUuid: this.$route.params.uuid,
      }
      if (this.createdByUuid) {
        body={...body,createdByUuid:this.createdByUuid}
      }
      getAPI
        .post("/lead-logs/filterList",body)
        .then((res) => {
          this.loading = false;
          this.logs = res.data.result;
          this.totallogs = res.data.count;
        })
        .catch((err) => {
          this.loading = false;
        });
    },

    formatDate(date) {
      const d = moment(date).format("MM/DD/YYYY");
      return d;
    },

    getFieldsChanges(){
        if (this.expanded.length==0) {           
            return
        }
        const obj =  this.expanded[0];
        if (obj.metadata&&obj.metadata.before) {
            const bf = obj.metadata.before;
            const af = obj.metadata.after;
            for (const property in bf) {
                if (bf[property]!=af[property]) {
                    if (property!='pictures') {
                        this.fieldchanges.push({
                        label:property,
                        valueb:bf[property],                       
                        valuea:af[property]
                    })
                    }else if(property=='pictures'&&bf[property].length!=af[property].length){
                        this.fieldchanges.push({
                        labelb:property,
                        valueb:bf[property],                       
                        valuea:af[property]
                    })
                    }
                  
                }
            }
        }
    },

    
  }, 
};
</script>
<style lang="scss" scoped>
.wrapper-expanded {
  display: flex;
  justify-content: center;
  justify-self: center;
 width: 800px !important;
}
</style>
